export const isBrowser = () => typeof window !== "undefined"

/**
 * Don't trust this code!
 * Copied from https://stackoverflow.com/a/13419367/2438049
 */
export const parseQueryString = (queryString: string) => {
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&")

  return pairs.reduce((acc, pair) => {
    const [key, value] = pair.split("=")
    return {
      ...acc,
      [decodeURIComponent(key)]: decodeURIComponent(value),
    }
  }, {})
}

export const extractQueryString = (url: string) => {
  const idx = url.indexOf("?")
  return idx < 0 ? url : url.substr(idx + 1)
}

export const getQueryParams = (url: string): any =>
  parseQueryString(extractQueryString(url))
