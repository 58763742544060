import React, { FC } from "react"
import styles from "./header.module.css"
import NavLink from "../../buttons/navlink"
import { Route } from "../../../constants/routes"
import { checkIsLoggedIn, logout } from "../../../services/auth"
import { navigate } from "gatsby"

const Header: FC = () => {
  const isLoggedIn = checkIsLoggedIn()
  if (!isLoggedIn) {
    return null
  }

  return (
    <header className={styles.component}>
      <div>
        <nav className={styles.nav}>
          <div className={styles.navMenu}>
            {checkIsLoggedIn() ? (
              <NavLink
                to={Route.Start}
                onClick={event => {
                  event.preventDefault()
                  logout()
                  navigate(Route.Start)
                }}
              >
                {"abmelden"}
              </NavLink>
            ) : null}
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
