import React, { FC } from "react"
import cn from "classnames"
import styles from "./spinner.module.css"

interface SpinnerProps {
  small?: boolean
}
const Spinner: FC<SpinnerProps> = ({ small }) => (
  <div className={cn(styles.component, small && styles.small)}>
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Spinner
