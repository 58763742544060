import React, { FC } from "react"
import { Link } from "gatsby"
import cn from "classnames"
import styles from "./navlink.module.css"

interface NavlinkProps {
  to: string
  onClick?: (evt: any) => void
  isBig?: boolean
}

const Navlink: FC<NavlinkProps> = ({ isBig, ...props }) => {
  return (
    <Link
      className={cn(styles.component, isBig && styles.isBig)}
      // activeClassName={styles.active}
      // partiallyActive={props.to !== "/"}
      {...props}
    />
  )
}

export default Navlink
