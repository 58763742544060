import get from "lodash/get"
import Cookies from "universal-cookie"
import { isBrowser } from "../utils/misc"
import { API_BASE_URL } from "../constants/api"

const COOKIE_TOKEN = "auth_token"
const COOKIE_PATH = "/"

interface Credentials {
  username: string
  password?: string
}

interface AuthResponse {
  token: string
  expiresAt: string
}

const getLoginToken = (): string | null => {
  if (!isBrowser()) {
    return null
  }
  const cookies = new Cookies()
  return cookies.get(COOKIE_TOKEN)
}

const login = (login: AuthResponse) => {
  const cookies = new Cookies()
  cookies.set(COOKIE_TOKEN, login.token, {
    path: COOKIE_PATH,
    expires: new Date(login.expiresAt),
    secure: false, // TODO: set true on prod
    httpOnly: false, // TODO: set true on prod
  })
}

export const checkIsLoggedIn = () => !!getLoginToken()

export const logout = () => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_TOKEN, { path: COOKIE_PATH })
}

export const handleLogin = async (user: Credentials) => {
  try {
    const res = await fetch(`${API_BASE_URL}/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        name: user.username,
        pass: user.password,
      }),
    })
    const loginData = await res.json()
    if (loginData.error) {
      throw new Error(get(loginData, "error.msg", "Login failed"))
    }
    login(loginData)
    return true
  } catch (err) {
    logout()
    return false
  }
}
