import "../../styles/fonts.css"
import "../../styles/base.css"
import "../../styles/helpers.css"
import React, { FC } from "react"
import Header from "./header"
import styles from "./layout.module.css"

const Layout: FC = ({ children }) => (
  <>
    <Header />
    <main className={styles.main}>{children}</main>
  </>
)

export default Layout
